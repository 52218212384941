'use-client'
import { initializeApp } from 'firebase/app'
import { getAuth, FacebookAuthProvider, GoogleAuthProvider } from 'firebase/auth'
import { getStorage } from 'firebase/storage'
import { collection,QueryConstraint, doc, DocumentReference,updateDoc,onSnapshot,SnapshotOptions,Firestore,FirestoreError, getFirestore, QueryDocumentSnapshot, Query,Timestamp, getDoc as getDocFirestore, getDocs as getDocsFirestore, QuerySnapshot, DocumentData, CollectionReference, query, where, orderBy, limit, } from 'firebase/firestore'


const firebaseConfig = {
	apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
	authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
	databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
	projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
	storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
	appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID
}

/** @deprecated */
export const firebaseInitializeApp = initializeApp(firebaseConfig)

export const facebookProvider = new FacebookAuthProvider()
export const googleProvider = new GoogleAuthProvider()

/** @deprecated */
export const firestore = getFirestore(firebaseInitializeApp)
export const storage = getStorage(firebaseInitializeApp)
export const auth = getAuth(firebaseInitializeApp)
export default firebaseInitializeApp


export async function getDoc<T>(reference: DocumentReference<T>, file = 'not declared') {
	await fetch('/api/logMessage', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			message: `[firestore | ${file}]: path: ${reference.path} user: ${auth.currentUser?.uid}`,
		}),
	});


	return getDocFirestore(reference)
}


// Helper function to get the path from a Query object
export function getQueryPath(query: Query<any>): string {
	try {
		// Try to safely access internal properties
		const queryInternal = query as any;

		// Different versions of Firebase might have different internal structures
		if (queryInternal._query && queryInternal._query.path) {
			return queryInternal._query.path.toString();
		}

		if (queryInternal._path) {
			return queryInternal._path.toString();
		}

		// If we can't access the path directly, return "unknown"
		return "unknown-path";
	} catch (error) {
		console.error("Error getting query path:", error);
		return "error-getting-path";
	}
}


export async function getDocs<T>(query: Query<T>, file = 'not declared') {
	// Use our helper function to get the path
	const path = getQueryPath(query);

	await fetch('/api/logMessage', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			message: `[firestore | ${file}]: path: ${path} user: ${auth.currentUser?.uid}`,
		}),
	});
	return getDocsFirestore(query);
}

export { doc, collection, getFirestore,QueryConstraint, updateDoc,DocumentReference, QuerySnapshot,Timestamp,Firestore,FirestoreError, CollectionReference, QueryDocumentSnapshot, onSnapshot, query, where, Query, orderBy, limit }
export type { DocumentData , SnapshotOptions}