import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"
import { Timestamp } from "@/config/firebase";
import { RawTimestamp } from "@/components/Appointments/utils/filterMandatoryFields";
import { IProvider, ITimestamp, Specialties, TSpecialties } from "@umahealth/entities";


export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}


/**
 * Convierte un objeto con formato de timestamp serializado a una instancia de Timestamp de Firebase
 * 
 * @param timestampData - Objeto RawTimestamp, o null/undefined
 * @returns Instancia de Timestamp o null si el input es nulo/indefinido
 */
export function deserializeTimestamp(
  timestampData: RawTimestamp | null | undefined
): Timestamp | null {
  if (!timestampData || typeof timestampData._seconds !== 'number') {
    return null;
  }

  return new Timestamp(
    timestampData._seconds,
    timestampData._nanoseconds || 0
  );
}

/**
 * Deserializa todos los campos timestamp en un objeto ITimestamp
 */
function deserializeNestedTimestamps(
  timestamps: ITimestamp<RawTimestamp>
): ITimestamp<Timestamp> {
  const result: ITimestamp<Timestamp> = {};

  for (const [key, value] of Object.entries(timestamps)) {
    if (value) {
      result[key as keyof ITimestamp] = deserializeTimestamp(value as RawTimestamp) ?? undefined
    }
  }

  return result;
}

/**
 * Deserializa todos los campos de tipo timestamp en un objeto IProvider
 * 
 * @param provider - Objeto provider con timestamps serializados
 * @returns Objeto provider con timestamps convertidos a instancias Timestamp
 */
export function deserializeProviderTimestamps(
  provider: IProvider<RawTimestamp>
): IProvider<Timestamp> {
  const result = { ...provider } as IProvider<Timestamp>;

  // Convertir fechas de expiración
  if (provider.expireDni) {
    result.expireDni = deserializeTimestamp(provider.expireDni) ?? undefined;
  }

  if (provider.expireInsurance) {
    result.expireInsurance = deserializeTimestamp(provider.expireInsurance) ?? undefined;
  }

  if (provider.expireMatricula) {
    result.expireMatricula = deserializeTimestamp(provider.expireMatricula) ?? undefined;
  }

  // Convertir timestamps anidados si existen
  if (provider.timestamps) {
    result.timestamps = deserializeNestedTimestamps(provider.timestamps);
  }

  return result;
}

export function deepEqual(a: any, b: any) {
  // Si son estrictamente iguales, son iguales
  if (a === b) return true;

  // Si alguno no es objeto o es null, no son iguales
  if (typeof a !== 'object' || a === null || typeof b !== 'object' || b === null) {
    return false;
  }

  // Obtener las claves de ambos objetos
  const keysA = Object.keys(a);
  const keysB = Object.keys(b);

  // Si tienen diferente cantidad de propiedades, no son iguales
  if (keysA.length !== keysB.length) return false;

  // Verificar recursivamente cada propiedad
  for (const key of keysA) {
    // Si b no tiene la misma propiedad o sus valores no son iguales, retornar false
    if (!keysB.includes(key) || !deepEqual(a[key], b[key])) {
      return false;
    }
  }

  // Si todas las propiedades son iguales, retornar true
  return true;
}

export function debounce<T extends (...args: any[]) => void>(
  func: T,
  wait: number
): (...args: Parameters<T>) => void {
  let timeout: ReturnType<typeof setTimeout> | null;
  return function (...args: Parameters<T>): void {
    if (timeout !== null) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      func(...args);
    }, wait);
  };
}

export function orderBy(arr: [], property: string, order = 'asc') {
  return arr.slice().sort((a, b) => {
    const aVal = a[property];
    const bVal = b[property];

    // Si ambos son números, hacer comparación numérica
    if (typeof aVal === 'number' && typeof bVal === 'number') {
      return order === 'asc' ? aVal - bVal : bVal - aVal;
    } else {
      // Para cualquier otro tipo, convertir a cadena y usar localeCompare
      const comparison = String(aVal).localeCompare(String(bVal));
      return order === 'asc' ? comparison : -comparison;
    }
  });
}

export function getReadableSpecialty(specialty: TSpecialties | string): string {
  switch (specialty) {
    case Specialties.FITNESS_CERTIFICATE:
      return "Apto físico";
    case Specialties.AUDIOLOGY_OTONEUROLOGY_PHONIATRY:
      return "Audiología, Otoneurología y Foniatría";
    case Specialties.AUDIT:
      return "Auditoría";
    case Specialties.CARDIOLOGY:
      return "Cardiología";
    case Specialties.SURGERY:
      return "Cirugía";
    case Specialties.INTERNAL_MEDICINE:
      return "Clínica Médica";
    case Specialties.DERMATOLOGY:
      return "Dermatología";
    case Specialties.DIABETOLOGY:
      return "Diabetología";
    case Specialties.ENDOCRINOLOGY:
      return "Endocrinología";
    case Specialties.PHLEBOLOGY:
      return "Flebología";
    case Specialties.GASTROENTEROLOGY:
      return "Gastroenterología";
    case Specialties.GYNECOLOGY:
      return "Ginecología";
    case Specialties.HEMATOLOGY:
      return "Hematología";
    case Specialties.INTERNIST:
      return "Internista";
    case Specialties.SPORTS_MEDICINE:
      return "Medicina del Deporte";
    case Specialties.FAMILY_MEDICINE:
      return "Medicina Familiar";
    case Specialties.GENERAL_MEDICINE:
      return "Medicina General";
    case Specialties.OCCUPATIONAL_MEDICINE:
      return "Medicina Laboral";
    case Specialties.PULMONOLOGY:
      return "Neumonología";
    case Specialties.NUTRITIONIST:
      return "Nutrición";
    case Specialties.NEUROLOGY:
      return "Neurología";
    case Specialties.OPHTHALMOLOGY:
      return "Oftalmología";
    case Specialties.ONCOLOGY:
      return "Oncología";
    case Specialties.OTORHINOLARYNGOLOGY:
      return "Otorrinolaringología";
    case Specialties.PEDIATRICS:
      return "Pediatría";
    case Specialties.PSYCHOLOGY:
      return "Psicología";
    case Specialties.PSYCHIATRY:
      return "Psiquiatría";
    case Specialties.RHEUMATOLOGY:
      return "Reumatología";
    case Specialties.TRAUMATOLOGY:
      return "Traumatología";
    case Specialties.UROLOGY:
      return "Urología";
    case Specialties.PALLIATIVE_HOME_CARE:
      return "Cuidados Paliativos (cuidados domiciliarios)";
    case Specialties.PEDIATRIC_HOME_CARE:
      return "Pediatría (cuidados domiciliarios)";
    case Specialties.CLINICAL_HOME_CARE:
      return "Clínica Médica (cuidados domiciliarios)";
    case Specialties.EXECUTIVE_HEALTH_HOME_CARE:
      return "Ejecutivo de Salud (cuidados domiciliarios)";
    case Specialties.PHARMACOVIGILANCE:
      return "Farmacovigilancia";
    case "cardiologia":
      return "Cardiología"
    default:
      return specialty;
  }
}
